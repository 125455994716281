import React from 'react';
import Userss from './users.json';
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";

const Users = () => {
    let match = useRouteMatch();
    return (
      <div className="mods">
      
        <Switch>
          <Route path={`${match.path}/:userId`}>
            <User />
          </Route>
          <Route path={match.path}>
            <div className="modlist">
              { Userss.map(user => {
                 return(
                   <Link to={`${match.url}/${user.username}`} className="link">
                    <div className="mod" key={ user.username }>
                      <img src={user.profileimg} alt="" className="userimg"></img>
                        <div className="modtexts">
                          <h2 className="text">{ user.displayname }</h2>
                          <p className="text">{ user.username }</p>
                          <p className="text" >{ user.bio }</p>
                       </div>
                      <p className="text">{ user.description }</p>
                   </div>
                  </Link>
                )
              }) }
            </div>
          </Route>
        </Switch>
      </div>
    );
}

function User() {
    let { userId } = useParams();
    const currentUser = Userss && Userss.find(user => `${user.username}` === userId); 
    // console.log('cmod',currentMod, Modss, modId);
    return (
      <div>
        <div className="modpage">
          <div>
            <img src={ currentUser.profileimg } alt="" className="userimg2"></img>
            <div>
              <h1 className="text">{ currentUser.displayname }</h1>
              <h2 className="text">@{ currentUser.username }</h2>
              <h3 className="text">{ currentUser.bio }</h3>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
export default Users