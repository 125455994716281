import React from 'react';
import Modss from './mods.json';
import Download from '../icons/Mods/Download.png'
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";

const Mods = () => {
    let match = useRouteMatch();
    return (
      <div className="mods">
      
        <Switch>
          <Route path={`${match.path}/:modId`}>
            <Mod />
          </Route>
          <Route path={match.path}>
            <div className="modlist">
              { Modss.map(mod => {
                 return(
                   <Link to={`${match.url}/mod=${mod.id}`} className="link">
                    <div className="mod" key={ mod.id }>
                      <img src={mod.logo} alt="" className="modicon"></img>
                        <div className="modtexts">
                          <h2 className="text">{ mod.name }</h2>
                          <p className="text" >Game Version: { mod.version }</p>
                          <p className="text" >Mod Version: { mod.version2 }</p>
                          <p className="text">Downloads: { mod.downloads }</p>
                          <p className="text">Rating: { mod.rateing }</p>
                       </div>
                      <p className="text">{ mod.description }</p>
                   </div>
                  </Link>
                )
              }) }
            </div>
          </Route>
        </Switch>
      </div>
    );
}

function Mod() {
    let { modId } = useParams();
    const currentMod = Modss && Modss.find(mod => `mod=${mod.id}` === modId); 
    // console.log('cmod',currentMod, Modss, modId);
    return (
      <div>
        <div className="modpage">
          <div>
            <div className="moddownload">
              <h1 className="text">{ currentMod.name }</h1>
              <a href={ currentMod.url } target="_blank" rel="noreferrer">
                <img src={Download} alt="" className="moddownloadimg"></img>
              </a>
            </div>
            <h2 className="text">Images:</h2>
            <div>
              <img src={ currentMod.logo } alt="" className="modimg"></img>
              <img src={ currentMod.logo } alt="" className="modimg"></img>
            </div>
            <h2 className="text">Description:</h2>
            <h3 className="text">{ currentMod.description }</h3>
          </div>
          <div>
            <div className="userprofile">
              <Link to={ currentMod.owner.url } className="link">
                <img src={currentMod.ownerprofile} alt="" className="userimg"></img>
                <h2 className="text">Made By: { currentMod.owner.dname }</h2>
              </Link>
            </div>
            <h3 className="text">Published: { currentMod.published } </h3>
            <h3 className="text">Updated: { currentMod.updated }</h3>
            <h3 className="text">Game Version: { currentMod.version }</h3>
            <h3 className="text">Mod Version: { currentMod.version2 }</h3>
            <h3 className="text">Category: { currentMod.category }</h3>
            <h3 className="text">Rateing: { currentMod.rateing }</h3>
          </div>
        </div>
      </div>
    )
  }
  
export default Mods
