import logo from './logo.png';
import React from 'react';
import Mods from './Mods/Mods.jsx';
import GamePatches from './Patches/Patches.jsx'
import Users from './accounts/user.jsx'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';

function App() {
  return (
    <Router>
      <div>
        <div className="topbar">
		    	<Link className="link" to="/">
		    	  <div className="topbaricon">
						  <img src={logo} className="icon" alt="logo" />
		  			  <h1 className="topbartext">ScrappyMods</h1>
			      </div>
		      </Link>
          <div className="topbarbuttons">
            <Link className="link topbaricon" to="/Mods">
              <h1 className="topbartext">Mods</h1>
            </Link>
            <Link className="link" to="/gamepatches">
              <h1 className="topbartext">Game Patches</h1>
            </Link>
            <Link className="link" to="/texturepacks">
              <h1 className="topbartext">Texture Packs</h1>
            </Link>
          </div>
          <div>
            <h1 className="topbartext">Account  ˅</h1>
          </div>
        </div>
        <div className="content">
          <Switch>
            <Route path="/Mods">
              <Mods />
            </Route>
            <Route path="/gamepatches">
              <GamePatches />
            </Route>
            <Route path="/texturepacks">
              <TexturePacks />
            </Route>
            <Route path="/user">
              <Users />
            </Route>
         </Switch>
        </div>
      </div>
    </Router>
  );
}

function TexturePacks() {
  return (
    <h1>hello</h1>
  )
}

export default App;
