import React from 'react';
import Patches from './gamepatches.json';
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";

const GamePatches = () => {
    let match = useRouteMatch();
    return (
      <div className="mods">
      
        <Switch>
          <Route path={`${match.path}/:patchId`}>
            <Patch />
          </Route>
          <Route path={match.path}>
            <div className="modlist">
              { Patches.map(patch => {
                 return(
                   <Link to={`${match.url}/patch=${patch.id}`} className="link">
                    <div className="mod" key={ patch.id }>
                      <img src={patch.logo} alt="" className="modicon"></img>
                        <div className="modtexts">
                          <h2 className="text">{ patch.name }</h2>
                          <p className="text">Game Version: { patch.version }</p>
                          <p className="text">Patch Version: { patch.version2 }</p>
                          <p className="text">Downloads: { patch.downloads }</p>
                          <p className="text">Rateing: { patch.rateing }</p>
                       </div>
                      <p className="text">{ patch.description }</p>
                   </div>
                  </Link>
                )
              }) }
            </div>
          </Route>
        </Switch>
      </div>
    );
}

function Patch() {
    let { patchId } = useParams();
    const currentpatch = Patches && Patches.find(patch => `patch=${patch.id}` === patchId); 
    // console.log('cpatch',currentpatch, patchss, patchId);
    return (
      <div>
        <div className="modpage">
          <div>
            <h1 className="text">{ currentpatch.name }</h1>
            <h2 className="text">Images:</h2>
            <div>
              <img src={ currentpatch.logo } alt="" className="modimg"></img>
              <img src={ currentpatch.logo } alt="" className="modimg"></img>
            </div>
            <h2 className="text">Description:</h2>
            <h3 className="text">{ currentpatch.description }</h3>
          </div>
          <div>
            <div className="userprofile">
              <img src={currentpatch.ownerprofile} alt=""></img>
              <h2 className="text">Made By: { currentpatch.owner }</h2>
            </div>
            <h3 className="text">Published: { currentpatch.published } </h3>
            <h3 className="text">Updated: { currentpatch.updated }</h3>
            <h3 className="text">Game Version: { currentpatch.version }</h3>
            <h3 className="text">Patch Version: { currentpatch.version2 }</h3>
            <h3 className="text">Category: { currentpatch.category }</h3>
            <h3 className="text">Rateing: { currentpatch.rateing }</h3>
          </div>
        </div>
      </div>
    )
  }
  
export default GamePatches